import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  FormData: FormGroup;
  PostContactFormUrl = 'https://formspree.io/mwkrnqka';
  recaptchaStr = '';
  contactFormLoading = false;
  contactFormSendingSucceeded = false;
  contactFormSendingFailed = false;

  constructor(private builder: FormBuilder, private http: HttpClient) {
   }

  ngOnInit() {
    this.FormData = this.builder.group({
      Fullname: new FormControl('', [Validators.required]),
      Email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
      Phone: new FormControl('', [Validators.required]),
      Comment: new FormControl('', [Validators.required]),
      'g-recaptcha-response': new FormControl('')
    });
  }

  submitForm() {
    this.contactFormSendingSucceeded = false;
    this.contactFormSendingFailed = false;
    this.contactFormLoading = true;
    this.FormData.disable();

    this.postMessageService(this.FormData.value).subscribe(response => {
      this.contactFormLoading = false;
      this.contactFormSendingSucceeded = true;
      this.FormData.enable();
      this.FormData.reset();
    }, error => {
      console.warn(error.responseText);
      this.contactFormLoading = false;
      this.contactFormSendingFailed = true;
      this.FormData.enable();
    });
  }

  onContactButtonClick(captchaRef: any): void {
    this.contactFormLoading = true;
    if (this.recaptchaStr) {
      captchaRef.reset();
    }
    captchaRef.execute();
    this.contactFormLoading = false;
  }

  public resolved(captchaResponse: string): void {
    this.recaptchaStr = captchaResponse;
    if (this.recaptchaStr) {
      this.FormData.patchValue({
        'g-recaptcha-response': this.recaptchaStr
      });
      this.submitForm();
    }
  }

  postMessageService(formData: any) {
    return this.http.post(this.PostContactFormUrl, formData, { responseType: 'text' }).pipe(
      map((response) => { if (response) { return response; } }, (error: any) => error)
    );
  }

  onContactFormResponseMessageCloseButtonClick(parent: string) {
    this[parent] = false;
  }
}
