<div class="how-work-area">
    <div class="container">              
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Recueil du besoin</h3>
                    <p>Nous recueillions vos attentes en identifiant les moyens nécessaires à la réussite du projet.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Confection de la solution</h3>
                    <p>Nous amorçons d'une manière itérative et agile la phase de solutioning tout en prenant en compte vos feedbacks au fil de l'eau.</p>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Mise à disposition des livrables</h3>
                    <p>Nous mettons à votre disposition les différents livrables convenus et nous vous accompagnons dans la mise en place des différents plans d'action.</p>
                </div>
            </div>
        </div>
    </div>
</div>