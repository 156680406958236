<div class="main-banner home-bg-one">
    <div class="creative-banner-two"></div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h1>Nous sommes
                        <span class="typewrite" data-period="1500"
                            data-type='[ "Professionnels", "Passionnés", "Créatifs", "ⵉⵎⴰⵡⴰⵙⵏ" ]'>
                            <span class="wrap"></span>
                    </span>
                    </h1>
                    <p>Votre partenaire de confiance pour vous accompagner dans vos projets éducatifs et associatifs.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-about-two></app-about-two>

<app-services-two></app-services-two>

<app-contact></app-contact>