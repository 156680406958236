<section id="contact" class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Restons en <span>Contact</span></h2>
            <p>Que ce soit pour une question, un renseignement ou tout simplement pour nous parler de votre projet, n'hésitez pas à utiliser l'un
                des moyens de mise en relation ci-dessous!
            </p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">

                <app-map></app-map>

                <div class="contact-info">
                    <ul>
                        <li><i class="fas fa-map-marker-alt"></i>App 9, imm 02 Résidence Imam Jazouli, Mhamid 7
                            Marrakech</li>
                        <li><i class="far fa-envelope"></i> <a
                                href="mailto:contact@imawasn-consulting.com">contact@imawasn-consulting.com</a></li>
                        <li><i class="fas fa-phone-alt"></i> <a href="tel:0661307739">(+212) 06 61 30 77 39</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <h3>Nous sommes impatients de vous lire</h3>

                    <form [formGroup]="FormData">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label for="Fullname">
                                        Nom et prénom
                                        <input type="text" class="form-control" name="Fullname" formControlName="Fullname">
                                    </label>
                                    <div *ngIf="FormData.controls['Fullname'].invalid && FormData.controls['Fullname'].dirty" class="alert alert-danger form-danger mt-1" role="alert">
                                        <div *ngIf="FormData.controls['Fullname'].errors.required">
                                            Veuillez renseigner votre nom et prénom
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label for="_replyto">
                                        Email
                                        <input type="email" class="form-control" name="_replyto" formControlName="Email">
                                    </label>
                                    <div *ngIf="FormData.controls['Email'].invalid && FormData.controls['Email'].dirty" class="alert alert-danger form-danger mt-1" role="alert">
                                        <div *ngIf="FormData.controls['Email'].errors.required || FormData.controls['Email'].errors.email">
                                            Veuillez renseigner une adresse mail valide
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label for="Phone">
                                        Numéro de téléphone
                                        <input type="text" class="form-control" name="Phone" formControlName="Phone">
                                    </label>
                                    <div *ngIf="FormData.controls['Phone'].invalid && FormData.controls['Phone'].dirty" class="alert alert-danger form-danger mt-1" role="alert">
                                        <div *ngIf="FormData.controls['Phone'].errors.required">
                                            Veuillez renseigner votre numéro de téléphone
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label for="Comment">
                                        Message
                                        <textarea class="form-control" name="Comment" formControlName="Comment" cols="30" rows="3" maxlength="500"></textarea>
                                    </label>
                                    <div *ngIf="FormData.controls['Comment'].invalid && FormData.controls['Comment'].dirty" class="alert alert-danger form-danger mt-1" role="alert">
                                        <div *ngIf="FormData.controls['Comment'].errors.required">
                                            Veuillez renseigner votre message
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" (click)="onContactButtonClick(captchaRef)" class="btn btn-primary" [ngClass]="{'btn-disabled':!FormData.valid}" [disabled]="!FormData.valid">Envoyer !</button>
                                <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)"></re-captcha>
                            </div>

                            <div class="col-lg-12 col-md-12 mt-2" *ngIf="contactFormSendingFailed">
                                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                                    Une erreur est survenue lors de l'envoi du formulaire, veuillez utiliser un autre moyen de contact. Nous vous prions de nous excuser pour la gêne occasionnée.
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true" (click)="onContactFormResponseMessageCloseButtonClick('contactFormSendingFailed')">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-2" *ngIf="contactFormSendingSucceeded">
                                <div class="alert alert-success alert-dismissible fade show" role="alert">
                                    Votre demande de contact a été envoyée avec succès, nous reviendrons très rapidement vers vous.
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true" (click)="onContactFormResponseMessageCloseButtonClick('contactFormSendingSucceeded')">&times;</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>