<nav class="navbar navbar-expand-lg navbar-light bg-light header-sticky">
    <div class="container">
        <a class="navbar-brand d-none d-sm-block" routerLink="/"><span>imawasn-consulting</span></a>
        <a class="navbar-brand d-sm-none" routerLink="/"><span>imawasn</span></a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link active" href="#home" (click)="onNavbarItemClick('home')">Accueil</a></li>
                <li class="nav-item"><a class="nav-link" href="#about" (click)="onNavbarItemClick('about')">Vision</a></li>
                <li class="nav-item"><a class="nav-link" href="#services" (click)="onNavbarItemClick('services')">Axes d'intervention</a></li>
                <li class="nav-item"><a class="nav-link" href="#contact" (click)="onNavbarItemClick('contact')">Contact</a></li>
            </ul>
        </div>
    </div>
</nav>
