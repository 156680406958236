<section id="services" class="services-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Nos <span>Axes d'intervention</span></h2>
            <p>Forts de notre expérience, nous vous proposons un accompagnement sur mesure dans les différents domaines suivants:</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-tasks"></i>
                    </div>
                    <h3>Conseils divers en management</h3>
                    <!--<p> - Détail -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-chalkboard-teacher"></i>
                    </div>
                    <h3>Ingénierie des formations</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-compass"></i>
                    </div>
                    <h3>Conseils divers en gestion des ressources humaines</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-graduation-cap"></i>
                    </div>
                    <h3>Formations diverses</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-search-dollar"></i>
                    </div>
                    <h3>Vente de prestations éducatives diverses et variées</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-calendar-check"></i>
                    </div>
                    <h3>Organisation des événements</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-school"></i>
                    </div>
                    <h3>Conseil et accompagnement en création des structures éducatives privées</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-stethoscope"></i>
                    </div>
                    <h3>Audit des établissements</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-chart-line"></i>
                    </div>
                    <h3>Gestion déléguée de structures éducatives privées</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-quote-right"></i>
                    </div>
                    <h3>Animation et organisation d’ateliers de formation</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <h3>Formation initiale et continue du personnel éducatif</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-child"></i>
                    </div>
                    <h3>Lutte contre l’analphabétisme</h3>
                </div>
            </div>
        </div>
    </div>
</section>