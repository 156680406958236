<section id="about" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Notre <span>Vision</span></h2>
            <p>Soucieux de partager et de mettre à disposition de ses
                partenaires une expérience de 40 ans dans les domaines éducatif et associatif dans les axes de
                management, de coaching et de l’orientation, le cabinet <span class="template_color">IMAWASN</span> propose ses services pour accompagner
                ses partenaires potentiels désireux d’innover dans les domaines cités dans un climat d’écoute, de
                partage et de suivi des projets vers une évolution satisfaisante concernant le management des
                organisations et environnemental ainsi que le coaching et l’appui à l’orientation.</p>
        </div>

        <app-how-we-work></app-how-we-work>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</section>