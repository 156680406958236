<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>Imawasn-consulting</span></a></h3>
        <ul>
            <li><a href="https://www.facebook.com/ImawasnConsulting/" target="_blank" class="fab fa-facebook-f" rel="noopener, noreferrer"></a></li>
            <li><a href="https://www.linkedin.com/in/abdallah-bouarfa-118aa234/" target="_blank" class="fab fa-linkedin-in" rel="noopener, noreferrer"></a></li>
        </ul>
        <p>Copyright <i class="far fa-copyright"></i> {{currentDate | date:'longDate'}} - Tous droits réservés.</p>
        <div class="logo">
            <img src="assets/img/logo/logo16-10.png" width="200px" alt="logo">
        </div>
    </div>
</footer>

<div class="go-top"><i class="fas fa-chevron-up"></i></div>