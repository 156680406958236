import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {

    @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
    map: google.maps.Map;
    lat: any = 31.5832493;
    lng: any = -8.0574042;

    ngAfterViewInit() {
        this.mapInitializer();
    }

    mapInitializer() {
        MapLoader.load().then(() => {
            const coordinates = new google.maps.LatLng(this.lat, this.lng);

            const mapOptions = {
                center: coordinates,
                zoom: 16
            };

            const marker = new google.maps.Marker({
                position: coordinates,
                map: this.map,
                title: 'imawasn-consulting'
            });

            this.map = new google.maps.Map(
                this.gmap.nativeElement,
                mapOptions
            );
            marker.setMap(this.map);
        });
    }
}

export class MapLoader {
    private static promise: Promise<any>;
    private static mapsApiKey: string = environment.firebaseConfig.apiKey;

    public static load() {
        if (!MapLoader.promise) {
            MapLoader.promise = new Promise((resolve) => {
                const onGapiLoadedky = '__onGapiLoaded';
                window[onGapiLoadedky] = () => {
                    resolve(null);
                };
                const node = document.createElement('script');
                node.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.mapsApiKey + '&callback=__onGapiLoaded';
                node.type = 'text/javascript';
                document.getElementsByTagName('head')[0].appendChild(node);
            });
        }

        return MapLoader.promise;
    }
}
