export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyDu_drQaLafZm5d7DbPuVmqiAAFLNw3H-c",
    authDomain: "imawasn-consulting-a7f7b.firebaseapp.com",
    databaseURL: "https://imawasn-consulting-a7f7b.firebaseio.com",
    projectId: "imawasn-consulting-a7f7b",
    storageBucket: "imawasn-consulting-a7f7b.appspot.com",
    messagingSenderId: "643177528834",
    appId: "1:643177528834:web:8c048c5d47307ff1bf311b",
    measurementId: "G-009S4Q71NB"
  }
};
