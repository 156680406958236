import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RecaptchaModule, RecaptchaSettings, RECAPTCHA_NONCE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { AboutTwoComponent } from './components/common/about-two/about-two.component';
import { AboutComponent } from './components/common/about/about.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { MapComponent } from './components/common/map/map.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { ServicesComponent } from './components/common/services/services.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { TeamComponent } from './components/common/team/team.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { WorkComponent } from './components/common/work/work.component';
import { HomeComponent } from './components/home/home.component';
import { GlobalErrorHandler } from './handlers/globalErrorHandler';

registerLocaleData(localeFr, 'fr');
const globalSettings: RecaptchaSettings = { siteKey: '6LddSaoZAAAAAHig88KoYEM5-Zq9-cd0t-6rwBpw', theme: 'light', size: 'invisible', badge: 'inline' };


@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        WhoWeAreComponent,
        CtaComponent,
        SkillComponent,
        FunfactsComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        ServicesTwoComponent,
        BlogDetailsComponent,
        MapComponent,
        HomeComponent,
        AboutTwoComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        RecaptchaModule,
        provideFirebaseApp(() => initializeApp(environment.firebaseConfig))
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: globalSettings
        },
        {
            provide: RECAPTCHA_NONCE,
            useValue: 'YWIyNDQ2ZmIwYjRlMmI3N2Y2Y2Y2NTQ1ODgzZmQxZDEzMDBhYjI3ZDhkOGY0MDdiNzQ3ZjU2NTQ2N2YyMTI0Mw=='
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
